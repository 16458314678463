<template>
  <div>
	  <loading></loading>
	  <div id="components-layout-demo-basic">
	  	<a-layout>
	  		<a-layout-sider v-if="false">Sider</a-layout-sider>
	  		<a-layout>
	  			<a-layout-header style="background: #FFFFFF;height: 60px;box-shadow: 0px 4px 10px 0px rgba(78, 89, 105, 0.06); position: relative; z-index: 300; ">
	  				<Header></Header>
	  			</a-layout-header>
	  			<a-layout-content style="min-height: calc(100vh - 280px);background-color: #F8F8F8;z-index: 10; margin-top: 0px;">
	  				<router-view v-if="isRouterAlive" class="animate__animated animate__fadeIn"></router-view>
	  			</a-layout-content>
	  			<a-layout-footer>
	  				<Footer></Footer>
	  			</a-layout-footer>
	  		</a-layout>
	  	</a-layout>
	  </div>
  </div>
</template>

<script>
	import loading from '../components/common/loading.vue'
import Header from '../components/common/header.vue'
	import Footer from '../components/common/footer.vue'
	export default{
		name:'APP',
		components:{
			Header,
			Footer,
			loading
		},
		data() {
			return {
				isRouterAlive: true,
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(() => {
					this.isRouterAlive = true;
				})
			}
		},
		watch: {
			'$store.state.trigger.changePlatformTigger' :function() {
				this.reload();
			}
		}
	}
</script>
<style>
	#components-layout-demo-basic {
		text-align: center;
		width: 100%;
		height: 100%;
		overflow-y: hidden;
	}

	#components-layout-demo-basic .ant-layout-header,
	#components-layout-demo-basic .ant-layout-footer {
		
		color: #fff;
	}

	#components-layout-demo-basic .ant-layout-footer {
		line-height: 1.5;
	}

	#components-layout-demo-basic .ant-layout-sider {
		background: #3ba0e9;
		color: #fff;
		line-height: 120px;
	}

	#components-layout-demo-basic .ant-layout-content {
		background: rgba(16, 142, 233, 1);
		color: #fff;
		min-height: 120px;
		/* line-height: 120px; */
	}

	#components-layout-demo-basic>.ant-layout {
		margin-bottom: 48px;
	}

	#components-layout-demo-basic>.ant-layout:last-child {
		margin: 0;
	}
	.ant-layout-header{
		margin: 0;
		padding: 0;
	}
	.ant-layout-footer{
		margin: 0;
		padding: 0;
	}
</style>
