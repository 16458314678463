<template>
	<div v-if="spinnings"  style="z-index: 8999; position: fixed;top: 0;width: 100%;height: 100%;display: flex;flex-direction: column;  justify-content: center;align-items: center;background-color: rgba(1,1,1,0.2)">
		 <!-- <a-icon type="close" style="color: white;font-size: 25px;cursor: pointer;" /> -->
		
		 <a-spin  size="large" tip="正在加载中..." />
		 <!-- <div  @click="close" style="font-size: 23px;color: #1C8EF7;margin-top: 12px;cursor: pointer;">加载超时?点击此处关闭</div> -->
	</div>
</template>

<script>
	import {mapState, mapMutations, mapGetters, mapActions} from 'vuex'
	export default {
	  data() {
	    return {
	    };
	  },
	  computed:{
		  ...mapState(['spinnings','loadingClose'])
	  },
	  mounted() {
	  	console.log(this.spinnings)
	  },
	  methods:{
		  close(){
			   this.$store.commit('updateSpin',false)
			   // this.$store.commit('updateClose',false)
		  }
	  },
	 //  updated() {
		// co
		//   var that = this
		   
	 //  	setTimeout(function(){
		// 	that.$store.commit('updateClose',true)
		// },1500)
	 //  },
	 
	};
</script>

<style>
</style>
