<template>
	<div class="headers">
		<div style=" display: flex;height: 60px;align-items: center; min-width: 1200px;">
			<div style="flex-grow: 1;">
				<img class="header_logo" src="../../assets/logo.png">
			</div>
			<div style="flex: 9;width: 0; text-align: center; display: flex; justify-content: center;">
				<Menu style="width: 100%;"></Menu>
			</div>
			<div style=" display: flex;height: 60px; ">
				<div v-if="userInfo.isManager" >
					<a-button type="link" @click="handleGoToBg"> 
						跳转后台
					</a-button>
				</div>
				<div :title="'欢迎您，' + userInfo.name" class="head_box" @click="goToCore"
					style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; min-width:150px;">
					<img v-if="userInfo.photoMedia" class="head" :src="`${userInfo.photoMedia}`">
					<a-avatar v-else style="background: #00a870;">
						{{ userInfo.name ? userInfo.name[userInfo.name.length - 1] : ''}}
					</a-avatar>
					<span
						style="font-size: 14px;font-weight: normal;color: rgba(0, 0, 0, 0.85);padding-left: 7px; ">欢迎您，</span>
					<span
						style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 42px;color: rgba(0, 0, 0, 0.85);">{{ userInfo.name }}</span>
				</div>
			</div>
			<div  style="display: flex; align-items: center; white-space: nowrap;position: relative; height: 60px; padding: 0px 10px; border-right:1px solid #EBEBEB ;">
				<div style="padding: 6px; width: 34px; height: 34px; display: flex;margin-right: 8px;" class="logos">
						<img  src="../../assets/search.png" @click="goToSearch">
					</div>
					<a-tooltip title="查看消息" @click="headTipsVisible = true; ">
						<a-badge :count="userInfo.noReadCount">
							<div style="padding: 6px; width: 34px; height: 34px; display: flex;" class="logos">
								<img  src="../../assets/tip.png">
							</div>
						</a-badge>
					</a-tooltip>
					<div @mousemove="headTipsVisible = true">
						<HeadTips
							:class="headTipsVisible ? 'animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut none'"
							:visible="headTipsVisible" @onClose="headTipsVisible = false">
						</HeadTips>
					</div>
			</div>
			<div class="close_box" >
				<a-popconfirm title="请确认是否注销登录?" ok-text="是" cancel-text="否" @confirm="logout">
					<div class="logos"  style="padding: 6px;width: 32px; height: 32px; display: flex; align-items: center; justify-content: center; margin: 0 auto;">
						<img style="width: 24px; height: 24px;" src="../../assets/close.png">
					</div>
				</a-popconfirm>
			</div>
			<div class="platform_box">
					<a-dropdown-button icon="切换">
						<a-tooltip placement="bottom">
							<template slot="title">
								<span>{{ currentPlatform }}</span>
							</template>
							<div style="max-width: 150px; overflow: hidden; text-overflow: ellipsis;">
								{{ currentPlatform }}
							</div>
						</a-tooltip>
						<a-menu slot="overlay">
							<a-menu-item v-for="(item, index) in platformChangeList" :key="item.value"
								@click="handleChangePlatform(item)">
								{{ item.text }}
							</a-menu-item>
							<a-menu-item key="3" @click="handleAddPlatform">
								<a-icon type="plus" /> 添加平台
							</a-menu-item>
						</a-menu>
					</a-dropdown-button>
				</div>
		</div>
		<!-- <a-row style="min-width: 1300px;">
			<a-col :span="5" :lg="5" :xl="6" style="padding-left: 72px;text-align: left;">
				<img class="header_logo" src="../../assets/logo.png">
			</a-col>
			<a-col :span="9" :lg="9" :xl="8" style="margin-top: 8px;">
				<Menu style="width: 700px;"></Menu>
			</a-col>
			<a-col :span="10" :lg="10" :xl="10" style="display: flex;">
				<div style="flex: 1;"></div>
				<div v-if="userInfo.isManager" >
					<a-button type="link" @click="handleGoToBg"> 
						跳转后台
					</a-button>
				</div>
				<div :title="'欢迎您，' + userInfo.name" class="head_box" @click="goToCore"
					style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; min-width:150px;">
					<img v-if="userInfo.photoMedia" class="head" :src="`${userInfo.photoMedia}`">
					<a-avatar v-else style="background: #00a870;">
						{{ userInfo.name[userInfo.name.length - 1] }}
					</a-avatar>
					<span
						style="font-size: 14px;font-weight: normal;color: rgba(0, 0, 0, 0.85);padding-left: 7px; ">欢迎您，</span>
					<span
						style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 42px;color: rgba(0, 0, 0, 0.85);">{{ userInfo.name }}</span>
				</div>
				<div class="tips_box" style="white-space: nowrap;position: relative;">
					<img class="logos" src="../../assets/search.png" @click="goToSearch">
					<a-tooltip title="查看消息" @click="headTipsVisible = true; ">
						<a-badge :count="userInfo.noReadCount">
							<img class="logos" src="../../assets/tip.png">
						</a-badge>
					</a-tooltip>
					<div @mousemove="headTipsVisible = true">
						<HeadTips
							:class="headTipsVisible ? 'animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut none'"
							:visible="headTipsVisible" @onClose="headTipsVisible = false">
						</HeadTips>
					</div>
				</div>
				<div class="close_box">
					<a-popconfirm title="请确认是否注销登录?" ok-text="是" cancel-text="否" @confirm="logout">
						<img class="logos" src="../../assets/close.png">
					</a-popconfirm>

				</div>
				<div class="platform_box">
					<a-dropdown-button icon="切换">
						<a-tooltip placement="bottom">
							<template slot="title">
								<span>{{ currentPlatform }}</span>
							</template>
							<div style="max-width: 150px; overflow: hidden; text-overflow: ellipsis;">
								{{ currentPlatform }}
							</div>
						</a-tooltip>
						<a-menu slot="overlay">
							<a-menu-item v-for="(item, index) in platformChangeList" :key="item.value"
								@click="handleChangePlatform(item)">
								{{ item.text }}
							</a-menu-item>
							<a-menu-item key="3" @click="handleAddPlatform">
								<a-icon type="plus" /> 添加平台
							</a-menu-item>
						</a-menu>
					</a-dropdown-button>
				</div>
			</a-col>

		</a-row> -->
		<!-- <div style="height: 10px;background-color: #FBFBFE;width: 100%;position: absolute;left: 0;"></div> -->
		<a-modal v-if="addPlatformModalVisible" v-model="addPlatformModalVisible" :width="580" title="添加平台">
			<a-spin :spinning="loading">
				<div style="display: flex; align-items: center; width: 90%;">
					<div>
						邀请码:
					</div>
					<div style="flex: 1; padding: 0px 16px; width: 0px;">
						<a-input v-model="inviteCode" placeholder="请输入"></a-input>
					</div>
				</div>
			</a-spin>
			<template slot="footer">
				<div class="footer-btns">
					<a-button class="cancel-btn" @click="handleCancel"> 取消 </a-button>
					<a-button :class="[``, !isModify ? 'disabled-btn' : 'confirm-btn']" type="primary"
						:disabled="!isModify || loading" @click="handleConfirm"> 确认 </a-button>
				</div>
			</template>
		</a-modal>
	</div>
</template>

<script>
import { getUserInfo, logout } from '../../api/anotherIndex'
import { getCompanyList, bindingPlatform, changePlatform } from '@/api/others.js';
import Menu from './menu.vue'
import HeadTips from '../headTips.vue'
import { mapState } from 'vuex'
import { userInfo } from 'os';
import { message, Modal } from 'ant-design-vue';
import store from '../../store';
import { h } from 'vue-demi';
export default {
	name: 'Header',
	components: {
		Menu,
		HeadTips
	},
	computed: {
		...mapState(['userInfo']),
		isModify() {
			return this.inviteCode && this.inviteCode.toString().trim().length > 0;
		},
		currentPlatform() {
			if (!this.userInfo || !this.userInfo.tenantId) {
				return ''
			}
			else {
				const currentList = this.list.filter(item => item.value == this.userInfo.tenantId);
				return currentList && currentList.length > 0 ? currentList[0].text : '';
			}
		},
		platformChangeList() {
			if (!this.userInfo || !this.userInfo.tenantId) {
				return []
			}
			return this.list.filter(item => item.value != this.userInfo.tenantId);
		}
	},
	data() {
		return {
			headTipsVisible: false,
			msgHover: false,
			head: this.$store.state.userInfo.photoMedia,
			addPlatformModalVisible: false,
			inviteCode: '',
			loading: false,
			list: []
		}
	},
	methods: {
		headTipsOver() {
			this.headTipsVisible = true
		},
		goToSearch() {
			this.$router.push({
				path: '/search'
			})
		},
		async getUserInfo() {
			const res = await getUserInfo()
			if (res.success) {
				this.$store.commit('setUserInfo', res.data)
			}
		},
		async logout() {
			const res = await logout()
			if (res.success) {
				window.localStorage.clear();
				this.$store.commit('logout')
				this.$message.success('已成功注销登录')
				this.$router.push({ path: '/login' })
			}
		},
		goToCore() {
			this.$router.push({ path: '/core/basicInformation' })
		},
		handleGoToBg() {
			localStorage.setItem('pro__Access-Token', JSON.stringify({ value: localStorage.getItem('Authorization'), expire: null }));
			localStorage.setItem('pro__X-Access-Token', JSON.stringify({ value: localStorage.getItem('X-Authorization'), expire: null }))
			window.open(`${window.location.origin}/bg/`)
		},
		async getPlatform() {
			const res = await getCompanyList();
			if (res.code === 200) {
				this.list = res.data;
			}
		},
		handleAddPlatform() {
			this.inviteCode = '';
			this.addPlatformModalVisible = true;
		},
		handleCancel() {
			this.addPlatformModalVisible = false;
		},
		async handleConfirm() {
			this.loading = true;
			const res = await bindingPlatform({
				inviteCode: this.inviteCode
			});
			if (res.code === 200 || res.code === 204) {
				message.success({
					content: res.message
				})
				await this.getPlatform();
				this.addPlatformModalVisible = false;
			}
			this.loading = false;
		},
		async handleChangePlatform(item) {
			Modal.confirm({
				title: '提示',
				content: h('div', {}, [
					h('span', {}, '是否确认切换至'),
					h('span', { style: { color: '#1890ff' } }, item.text),
					h('span', {}, '？ 请先保存所有页面的编辑操作，避免数据丢失！')
				]),
				okText: '确认',
				cancelText: '取消',
				onOk: async () => {
					store.commit('updateSpin', true)
					const res = await changePlatform({
						tenantId: item.value,
						rememberPwd: localStorage.getItem('RememberPwd') ? true : false
					});
					if (res.code === 200 || res.code === 204) {
						store.commit('updateSpin', false);
						store.commit('setChangePlatformTimeStamp');
						message.success({
							content: res.message
						})
						store.commit('trigger/TOGGLE_CHANGE_PLATFORM');
						store.dispatch('callForUserInfo');
						this.$router.replace({
							name: 'workbench'
						})
					}
				}
			})

		}
	},
	created() {
		if (localStorage.getItem('Authorization')) {
			this.$store.dispatch('callForUserInfo')
		}
		if (localStorage.getItem('Authorization')) {
			//const res = 
			this.getPlatform();
		}
	}
}
</script>

<style scoped>
.header_logo {
	width: 128px;
	height: 40px;
	height: auto;
}

.head_box {
	display: flex;
	width: 164px;
	height: 64px;
	align-items: center;
	cursor: pointer;
	border-left: 1px solid #EBEBEB;
	border-right: 1px solid #EBEBEB;
	padding: 0 5px;
}

.head {
	border-radius: 100%;
	width: 40px;
	height: 40px;
}

.close_box {
	width: 60px;
	border-left: 1px solid #EBEBEB;
}

.tips_box {
	width: 121px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.logos {
	width: 20px;
	height: auto;
	cursor: pointer;
	border-radius: 5px;
}

.logos:hover {
	background-color: rgba(48, 83, 235, 0.25);
}

.none {
	display: none;
}

.ant-layout-header {
	padding: 0;
}

.heads {
	min-width: 1300px;
}
</style>
<style>
.headers .ant-badge-dot {
	right: 5px !important;
	top: 2px !important;
}

.platform_box {
	border-left: 1px solid #eee;
	padding: 0px 16px;
}
</style>
<style lang="less" scoped>
.footer-btns {
	text-align: center;


	.cancel-btn {
		background: #eeeeee;
	}
}
</style>
